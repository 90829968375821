(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/wapi-integration/assets/javascripts/wapi-integration.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/wapi-integration/assets/javascripts/wapi-integration.js');
"use strict";


const logger = svs.core.log.getLogger('oddset_kambi:components:wapiIntegration');
const navigateTo = async path => {
  try {
    const wapi = await window.KambiWidget.ready;
    wapi.navigateClient(path);
  } catch (error) {
    logger.error('Kambi client navigation error: ', error);
  }
};
const showSportsbook = async () => {
  try {
    const wapi = await window.KambiWidget.ready;
    wapi.set(wapi.CLIENT_SHOW);
  } catch (error) {
    logger.error('Kambi client show sportsbook error: ', error);
  }
};
const hideSportsbook = async () => {
  try {
    const wapi = await window.KambiWidget.ready;
    wapi.set(wapi.CLIENT_HIDE);
  } catch (error) {
    logger.error('Kambi client hide sportsbook error: ', error);
  }
};
const clearBetslip = async () => {
  try {
    const wapi = await window.KambiWidget.ready;
    wapi.set(wapi.BETSLIP_CLEAR);
  } catch (error) {
    logger.error('Kambi clear betslip error: ', error);
  }
};
const hideBetslip = async () => {
  try {
    const wapi = await window.KambiWidget.ready;
    wapi.set(wapi.BETSLIP_HIDE);
  } catch (error) {
    logger.error('Kambi client hide betslip: ', error);
  }
};
const showBetslip = async () => {
  try {
    const wapi = await window.KambiWidget.ready;
    wapi.set(wapi.BETSLIP_SHOW);
  } catch (error) {
    logger.error('Kambi client show betslip: ', error);
  }
};
setGlobal('svs.oddset_kambi.components.wapiIntegration', {
  showSportsbook,
  hideSportsbook,
  clearBetslip,
  navigateTo,
  hideBetslip,
  showBetslip
});

 })(window);